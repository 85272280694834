<script lang="ts" setup>
import { computed } from 'vue'
import type Engagement from '~/src/Domain/Engagement/Engagement'
import type Phase from '~/src/Domain/Engagement/Phase'
import type Organisation from '~/src/Domain/Organisation/Organisation'
import type StepInterface from '~/src/Domain/WorkProgram/Step/StepInterface'
import useWorkProgramNavigation from '~/src/UserInterface/WorkProgram/composables/useWorkProgramNavigation'
import type WorkProgramProjection from '~/src/UserInterface/WorkProgram/projection/WorkProgramProjection'

const props = defineProps<{
  organisation: Organisation
  engagement: Engagement
  phase: Phase
  workProgram: WorkProgramProjection
  currentStep: StepInterface
}>()

const { getWorkProgramStepLocalePath } = useWorkProgramNavigation(computed(() => props.organisation), computed(() => props.engagement), computed(() => props.phase))
const hasPreviousStep = computed(() => props.workProgram.hasPreviousStep(props.currentStep))
const hasNextStep = computed(() => props.workProgram.hasNextStep(props.currentStep))
</script>

<template>
  <div class="d-flex justify-content-between py-2 px-4 bg-white border-bottom border-top">
    <ui-button
      v-if="hasPreviousStep"
      variant="white"
      :to="getWorkProgramStepLocalePath(workProgram, workProgram.getPreviousStep(currentStep))"
    >
      <ui-icon icon="chevron-left" />
      {{ $t('components.work_program.btn_previous') }}
    </ui-button>
    <!-- Empty SPAN to flex-align other button at the end -->
    <span v-else>&nbsp;</span>

    <portal-target id="work-program-footer-button">
      <ui-button
        v-if="hasNextStep"
        class="align-self-end"
        :to="getWorkProgramStepLocalePath(workProgram, workProgram.getNextStep(currentStep))"
      >
        {{ $t('components.work_program.btn_next') }}
        <ui-icon icon="chevron-right" />
      </ui-button>
    </portal-target>
  </div>
</template>
