<template>
  <b-dropdown-header>
    <template
      v-for="(_, name) in $slots"
      #[name]="scope"
    >
      <slot
        :name
        v-bind="scope ?? {}"
      />
    </template>
  </b-dropdown-header>
</template>
