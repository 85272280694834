<script lang="ts" setup>
import { computed } from 'vue'
import type Engagement from '~/src/Domain/Engagement/Engagement'
import type Phase from '~/src/Domain/Engagement/Phase'
import type Organisation from '~/src/Domain/Organisation/Organisation'
import type StepInterface from '~/src/Domain/WorkProgram/Step/StepInterface'
import useWorkProgramNavigation from '~/src/UserInterface/WorkProgram/composables/useWorkProgramNavigation'
import type WorkProgramProjection from '~/src/UserInterface/WorkProgram/projection/WorkProgramProjection'

const props = defineProps<{
  organisation: Organisation
  engagement: Engagement
  phase: Phase
  workProgram: WorkProgramProjection
  currentStep: StepInterface
}>()

const { getWorkProgramStepLocalePath } = useWorkProgramNavigation(computed(() => props.organisation), computed(() => props.engagement), computed(() => props.phase))
</script>

<template>
  <ui-dropdown variant="white">
    <template #button-content>
      <ui-icon icon="list" />
    </template>

    <ui-dropdown-header>
      {{ workProgram.title }}
    </ui-dropdown-header>

    <template v-for="section in workProgram.stepSectionCollection.stepSections">
      <!-- Only render the step when the section only has one step (to prevent double intro / final pages) -->
      <ui-dropdown-item
        v-if="!section.hasResultSteps()"
        :key="section.slug.toString()"
        :to="getWorkProgramStepLocalePath(workProgram, section.getFirstStep())"
      >
        {{ section.getFirstStep().title }}
      </ui-dropdown-item>

      <!-- And render a sub-section otherwise -->
      <ui-dropdown-group
        v-else
        :key="section.slug"
      >
        <ui-dropdown-header>
          {{ section.title }}
        </ui-dropdown-header>
        <ui-dropdown-item
          v-for="step in section.getVisibleSteps()"
          :key="step.slug.toString()"
          :to="getWorkProgramStepLocalePath(workProgram, step)"
        >
          {{ step.title }}
        </ui-dropdown-item>
      </ui-dropdown-group>
    </template>
  </ui-dropdown>
</template>
